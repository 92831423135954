export const colors = {
	black: '#000000',
	white: '#FFFFFF',
	blue1: '#E5E5FF',
	blue2: '#B9B9FA',
	blue3: '#8E8EF5',
	blue4: '#6565F0',
	blue5: '#3D3DEB',
	blue6: '#1717E5',
	blue7: '#0F0FBD',
	blue8: '#04046B',
	blue9: '#010142',
	blue10: '#000029',
	gray1: '#F0F0F5',
	gray2: '#DCDCE5',
	gray3: '#C4C4CC',
	gray4: '#ABABB2',
	gray5: '#939399',
	gray6: '#7A7A80',
	gray7: '#626266',
	gray8: '#49494D',
	gray9: '#313133',
	gray10: '#18181A',
	red: '#EE194B',
	red1: '#F1A0B3',
	red2: '#FFE7F4',
	currentColor: 'currentColor'
}

export type colorsKeyOfType = keyof typeof colors
