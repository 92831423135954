export const Icons = {
    Profile: "profile.webp",
    AppStore: "AppStore.webp",
    Indexer: "Indexer.svg",
    SM: "sm.webp",
    마음가짐: "Maeumgajim.webp",
    LookMonster: "monster.webp",
    Shalo: "Shalo.webp",
    Today: "today.webp",
    JunSal: "js.PNG.webp",
    Github: "github.webp",
    Web: "web.webp",
    Cafe: "5ce4b74d3a44d.webp",
    Junha06: "junha06.webp",
    AwesomeJunha: "AwesomeJunha.png",
    Discord: "discord.webp",
    Email: "email.webp",
    PerfectContributionsView: "PerfectContributionsView.webp",
    XCameraKit: "XCameraKit.webp",
    Graps: "Graps.webp",
    AVSlider: "AVSlider.webp",
    smBanner: "msBanner.webp",
    Tistroy: "TISTORY.webp",
    Mafia: "mafia.webp",
    ForcedBlockingSwift: "ForcedBlockingSwift.webp",
    Mint: "Mint.webp",
    Link: "link.webp",
    NFC: "NFC.webp",
    WeatherCard: "WeatherCard.webp",
    VisitHistoryWebView: "VisitHistoryWebView.png",
    Decofolio: "Decofolio.webp",
    JunNote: "JunNote.webp",
    JAGIJUDO: "JAGIJUDO.webp",
    Conty: "Conty.webp",
    JunLogo: "junLogo.webp"
}

export type keyOfIcons = keyof typeof Icons;