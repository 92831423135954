export class Regex {
	static url = /^(https*?:\/\/)?([\w-]+\.)+\w{2,}(\/\S*)?$/
	static inputKey = /Key[A-Z]|Numpad\d/
	static keys = [
		'Backquote',
		'Minus',
		'Equal',
		'Backspace',
		'Tab',
		'BracketLeft',
		'BracketRight',
		'Backslash',
		'Semicolon',
		'Quote',
		'Comma',
		'Period',
		'Slash',
		'Space',
		'NumpadDivide',
		'NumpadMultiply',
		'NumpadSubtract',
		'NumpadAdd',
		'NumpadDecimal',
		'NumpadEnter',
		'Backspace',
		'Delete',
		'Enter'
	]
}
