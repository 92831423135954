export const Colors = {
    Black0: "#000",
    Black1: "#101010",
    Black2: "#202020",
    Black3: "#303030",
    Black4: "#404040",
    Black5: "#505050",
    Black6: "#606060",
    White6: "#999",
    White5: "#AAA",
    White4: "#BBB",
    White3: "#CCC",
    White2: "#DDD",
    White1: "#EEE",
    White0: "#FFF",
    Red0: "#FF3030",
    Red1: "#CA1B1B",
    Blue0: "#3C3CFF",
    Blue1: "#1613AE",
    Green0: "#22FF38",
    Green1: "#19AB1F",
    Orange: "#FF902B",
    MGColor: "#3193F5"
};

export type keyOfColors = keyof typeof Colors